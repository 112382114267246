import React from "react";

import styled from "styled-components";
const Bg = styled.div`
  width: 100%;
  height: 300vh;
  background-color: #333;
`;

const Work2 = () => {
  return <Bg></Bg>;
};

export default Work2;
